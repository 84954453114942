.program{
display: flex;
flex-direction: column;
padding: 0 2rem;
}
.program-header{
    display: flex;
    gap: 5rem;
    font-weight: bold;
    font-size: 3rem;
    justify-content: center;
    color: wheat;
    text-transform: uppercase;
    font-style: italic;
}
.program-categories{
    display: flex;
    gap: 1rem;
}

.categroy{
    display: flex;
    flex-direction: column;
    background-color:gray;
    padding: 2rem;
    gap: 1rem;
    color: aliceblue;
    justify-content: space-between;
}
.categroy>:nth-child(1){
    width: 2rem;
    height: 2rem;
    fill: white;
}
.categroy>:nth-child(2){
    font-size: 1rem;
    font-weight: bold;
   
}
.categroy>:nth-child(3){
  
   line-height: 25px;
   font-size: 0.9rem;
}
.join-now{
    display: flex;
    align-items: center;
    gap: 2rem;
}

.join-now>img{
    width: 1rem;
}
.categroy:hover{
    background-color: rgba(55, 39, 8, 0.715);
    cursor: pointer;
}
@media screen and (max-width: 768px){
    .program-header
    {
        flex-direction: column;
        gap: 1rem;
        font-size: x-large;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
    }
    .program-categories{
        flex-direction: column;
    }
}