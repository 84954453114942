.join {
  display: flex;
  padding: 0 2rem;
  gap: 10rem;
}
.left-j {
  color: aliceblue;
  font-size: 3rem;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
}
.left-j > div {
  display: flex;
  gap: 1rem;
}
.left-j > hr {
  position: absolute;
  width: 10.5rem;
  border: 2px solid var(--orange);
  border-radius: 20%;
  margin: -10px 0px;
}
.right-j {
  align-items: flex-end;
  display: flex;
  justify-content: center;
}
.email-container {
  display: flex;
  gap: 3rem;
  background-color: grey;
  padding: 1rem;
}
.email-container > input {
  background-color: transparent;
  border: none;
  outline: none;
  color: var(--lightgray);
}
::placeholder{
    color: var(--lightgray);
}
.btn-j{
    background-color: var(--orange);
    color: wheat;
}
@media screen and (max-width: 768px){
  .join{
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .left-j{
    font-size: x-large;
    flex-direction: column;
  }
  .right-j{
    padding: 2rem;
  }
}